export const ar = {
  login: {
    mobileNumber: 'رقم الهاتف المحمول',
    password: 'كلمة المرور',
    btnTitle: 'تسجيل الدخول',
    forgetPassword: 'نسيت كلمة المرور؟',
    menuBtn: 'انظر القائمة',
    newUser: "أنا مستخدم جديد ،",
    subscribe: 'يشترك'
  },
  common: {
    english: 'إنجليزي',
    arabian: 'عربى',
    logout: 'Logout'
  },
  image: {
    title: 'أصبح تخطيط الوجبات الصحية والمخصص أمرًا سهلاً',
    siginTitle1: 'وجبة صحية ومخصصة',
    siginTitle2: 'أصبح التخطيط سهلاً',
    footerTitle: 'اختر أفضل غذاء غذائي لديك',
    healthy: 'صحيح،',
    personalized: 'شخصية',
    mealPlanning: 'تخطيط الوجبات',
    madeEasy: 'يجلعه سهلا',
    footer1: 'اختر افضل ما لديكم',
    footer2: 'أغذية التغذية'
  },
  verification: {
    title: 'سيتم إرسال رمز التحقق إلى هاتفك وستحتاج إلى إدخال الرمز للمتابعة',
    phoneNumber: 'أدخل رقم الهاتف',
    btnTitle: 'يكمل',
    alreadyAcc: 'لديك حساب بالفعل؟',
    login: 'تسجيل الدخول'
  },
  menu: {
    menu: 'قائمة الطعام',
    subTitle: 'هذا ما لديك',
    proceed: 'تقدم',
    mealName: 'يظهر اسم الوجبة هنا وهنا (الإفطار)'
  },
  forgetPassword: {
    title: 'نسيت كلمة المرور',
    subTitle: 'حدد تفاصيل الاتصال التي يجب أن نستخدمها لإعادة تعيين كلمة المرور الخاصة بك:',
    viaSms: 'عبر الرسائل القصيرة',
    viaEmail: 'عبر البريد الالكتروني',
    emailText: 'A Verfication code will be sent to your email and you will need to enter the code to proceed,',
    sendOtp: 'Send Otp',
    enterEmail: 'Enter email address',
    otpPhoneText: 'A Verfication code will be sent to your phone and you will need to enter the code to proceed'
  },
  otp: {
    title: 'Otp',
    subTitle: 'تم إرسال كلمة المرور لمرة واحدة إلى رقم هاتفك المحمول. الرجاء إدخال الرمز.',
    btnTitle: 'يكمل'
  },
  password: {
    title: 'كلمة المرور',
    newPassword: 'أدخل كلمة مرور جديدة',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    btnTitle: 'يكمل'
  },
  verifyByPhone: {
    subTitle: 'تم إرسال رمز التحقق إلى هاتفك الرجاء إدخاله والمتابعة',
    enterOtp: 'أدخل Otp',
    btnTitle: 'تحقق من هاتفي'
  },
  signUp: {
    title: 'اشتراك',
    male: 'ذكر',
    female: 'أنثى',
    firstName: 'الاسم الاول',
    lastName: 'اسم العائلة',
    dob: 'تاريخ الولادة',
    email: 'بريد الالكتروني',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    block: 'منع',
    street: 'شارع',
    area: 'مساحة',
    jadha: 'جدها',
    floor: 'الأرض',
    flatOrDoor: 'شقة / باب',
    defaultAddress: 'تعيين كعنوان افتراضي',
    home: 'مسكن',
    office: 'مكتب. مقر. مركز',
    others: 'آحرون',
    btnTitle: 'تقدم',
    subTitle1: 'صحية ومخصصة',
    subTitle2: 'تم التخطيط للوجبات',
    subTitle3: 'سهل'
  },
  health: {
    plan: 'سأختار الخطة',
    concern: 'لدي مخاوف صحية',
    allAndDis: 'الحساسية والكراهية',
    allergies: 'الحساسية',
    dislikes: 'يكره',
    weightLose: 'فقدان الوزن',
    maintainWeight: 'حافظ على الوزن',
    gainWeight: 'يزداد وزن',
    curBodyWeight: 'وزن جسدي الحالي',
    experts: 'خبراؤنا مستعدون لمساعدتك',
    dietcian: 'استشر اختصاصي التغذية لدينا',
    appointmentDate: 'اختر تاريخ الموعد',
    searchAllergies: 'بحث عن الحساسية',
    searchDislikes: 'بحث يكره',
    done: 'فعله',
    bookApoinment: 'موعد الكتاب',
    booked: 'حجز'
  },
  package: {
    choosePackage: 'اختر باقتك',
    healthyPlan: 'خطة صحية',
    requiredDays: 'اختر الأيام المطلوبة',
    startDate: 'حدد تاريخ البدء الخاص بك'
  },
  payment: {
    payment: 'دفع',
    paymentDetails: 'بيانات الدفع',
    total: 'مجموع',
    promoCode: 'إدخال الرمز الترويجي',
    apply: 'يتقدم',
    deliveryMethod: 'طريقة التوصيل',
    deliveryTo: 'يسلم ل',
    paymentDetail: 'تفاصيل الدفع',
    success: 'Your payment has been done successfully'
  },
  inputRules: {
    requiredField: 'هذا الحقل مطلوب',
    validEmail: 'يرجى إدخال البريد الإلكتروني الصحيح',
    number: 'يجب أن يكون رقم الهاتف 8 أرقام',
    pass_not_match: 'كلمة السر ليست جيدة',
    min_pass_6: 'يجب ألا تقل كلمة المرور عن 6 أحرف',
    max_pass_20: 'يجب ألا تزيد كلمة المرور عن 20 حرفًا',
    emailAlreadyExist: 'البريد الالكتروني موجود مسبقا',
    nameAlreadyExist: 'هذا الاسم موجود بالفعل'
  },
  home: {
    thanksForSignin: 'شكرا لك على التسجيل.',
    downloadApp: 'قم بتنزيل تطبيق الهاتف المحمول الخاص بنا الآن.'
  }
};