export const en = {
  login: {
    mobileNumber: 'Mobile Number',
    password: 'Password',
    btnTitle: 'Login',
    forgetPassword: 'Forgot Password?',
    menuBtn: 'See Menu',
    newUser: "I'm a new user,",
    subscribe: 'Subscribe'
  },
  common: {
    english: 'English',
    arabian: 'Arabic',
    logout: 'Logout'
  },
  image: {
    title: 'Healthy, Personalized Meal Planning Made Easy',
    siginTitle1: 'Healthy, Personalized Meal',
    siginTitle2: 'Planning Made Easy',
    footerTitle: 'Select Your Best Nutrition Food',
    healthy: 'Healthy,',
    personalized: 'Personalized',
    mealPlanning: 'Meal Planning',
    madeEasy: 'Made Easy',
    footer1: 'Select Your Best',
    footer2: 'Nutrition Food'
  },
  verification: {
    title: 'A Verfication code will be sent to your phone and you will need to enter the code to proceed',
    phoneNumber: 'Phone Number',
    btnTitle: 'Continue',
    alreadyAcc: 'Already have account?',
    login: 'Login'
  },
  menu: {
    menu: 'Menu',
    subTitle: 'Here’s what your having',
    proceed: 'Proceed',
    skip:'Skip',
    mealName: ' Meal name goes here and here (Breakfast)'
  },
  forgetPassword: {
    title: 'Forget Password',
    subTitle: 'Select which contact details should we use to reset your password:',
    viaSms: 'Via sms',
    viaEmail: 'Via email',
    emailText: 'A Verfication code will be sent to your email and you will need to enter the code to proceed,',
    sendOtp: 'Send Otp',
    enterEmail: 'Enter email address',
    otpPhoneText: 'A Verfication code will be sent to your phone and you will need to enter the code to proceed'
  },
  otp: {
    title: 'Otp',
    subTitle: 'One time password was sent to your mobile number. Please entethe code. ',
    btnTitle: 'Continue'
  },
  password: {
    title: 'Password',
    newPassword: 'Enter New Password',
    confirmPassword: 'Confirm New Password',
    btnTitle: 'Continue'
  },
  verifyByPhone: {
    subTitle: 'A Verfication code has been sent to your phone Please enter it and proceed',
    enterOtp: 'Enter Otp',
    btnTitle: 'Verify My Phone'
  },
  signUp: {
    title: 'SignUp',
    male: 'Male',
    female: 'Female',
    firstName: 'First Name',
    lastName: 'Last Name',
    dob: 'Date Of Birth',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    block: 'Block',
    street: 'Street',
    area: 'Area',
    jadha: 'Jadha',
    floor: 'Floor',
    flatOrDoor: 'Flat/Door',
    defaultAddress: 'Set as default address',
    home: 'Home',
    office: 'Office',
    others: 'Others',
    btnTitle: 'Proceed',
    subTitle1: 'Healthy, Personalized ',
    subTitle2: 'Meal Planning Made',
    subTitle3: 'Easy'
  },
  health: {
    plan: 'I will choose plan',
    concern: 'I have health concerns',
    allAndDis: 'Allergies & Dislikes',
    allergies: 'Allergies',
    dislikes: 'Dislikes',
    weightLose: 'Lose weight',
    maintainWeight: 'Maintain Weight',
    gainWeight: 'Gain Weight',
    curBodyWeight: 'My current body weight',
    experts: 'Our experts are ready to help you',
    dietcian: 'Consult our dietcian',
    appointmentDate: 'Choose appoitment date',
    searchAllergies: 'Search Allergies',
    searchDislikes: 'Search dislikes',
    done: 'Done',
    bookApoinment: 'Book Appointment',
    booked: 'Booked'
  },
  package: {
    choosePackage: 'Choose your package',
    healthyPlan: 'Healthy Plan',
    requiredDays: 'Choose required days',
    startDate: 'Select your start date'
  },
  payment: {
    payment: 'Payment',
    paymentDetails: 'Payment Details',
    total: 'Total',
    promoCode: 'Enter Promo Code',
    apply: 'Apply',
    deliveryMethod: 'Delivery Menthod',
    deliveryTo: 'Delivering to',
    paymentDetail: 'Payment Detail',
    success: 'Congratulations! Your Payment has been successfully completed!',
    failed: 'Your Payment Failed.  Please try again'
  },
  inputRules: {
    requiredField: 'This field is required',
    validEmail: 'Please enter a valid email',
    number: 'Phone number sholud be 8 digit',
    pass_not_match: 'Password not match',
    passwordMin: 'password should be minimum 6 characters',
    passwordMax: 'password should not be greater than 20 characters',
    phoneMax: 'Phone number should be 8 digits',
    phoneMin: 'Phone number should be 8 digits',
    emailAlreadyExist: 'Email already exist',
    nameAlreadyExist: 'This name already exist'
  },
  home: {
    thanksForSignin: 'Thank you for signing up.',
    downloadApp: ' Download our Mobile App Now.'
  }
};