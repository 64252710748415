import { required, email, max, min, confirmed, digits } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import i18n from '../../locales/i18n.js';
setInteractionMode('eager');

extend('required', {
	...required,
	message: i18n.t('inputRules.requiredField')
});

extend('max', {
	...max,
	message:field=> i18n.t(`inputRules.${field}Max`)
});

extend('min', {
	...min,
	message:field=> i18n.t(`inputRules.${field}Min`)
});
extend("phone", {
	...digits,
	message: "Phone number should be of 8 digits",
});
extend('email', {
	...email,
	message: i18n.t('inputRules.validEmail')
});


extend('minPhone', {
	...min,
	message: i18n.t('inputRules.number')
});

extend('minPass', {
	...min,
	message: i18n.t('inputRules.min_pass_6')
});

extend('maxPass', {
	...max,
	message: i18n.t('inputRules.max_pass_20')
});

extend('confirmed', {
	...confirmed,
	message: i18n.t('inputRules.pass_not_match')
});
