<template>
  <v-app class="main" v-scroll="onScroll">
    <!-- <app-main-navbar v-if="display"></app-main-navbar> -->
    <v-main >
      <router-view></router-view>
      <v-fab-transition>
        <v-btn
          fab
          bottom
          small
          right
          color="primary"
          id="scroll__top"
          fixed v-if="showScrollToTop"
          @click="scrollToTop"
        >
          <v-icon dark>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </v-fab-transition>
    </v-main>
    <!-- <v-snackbar v-model="snackbar" :color="snackbarObj.color">
      {{ snackbarObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          {{ $t("common.close") }}
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <v-row justify="center">
      <v-dialog v-model="deleteDialog" max-width="450">
        <v-card class="custom-font">
          <v-card-title class="primary--text"></v-card-title>
          <v-card-text class="primary--text font-weight-bold">{{
            $t("common.delete")
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="font-weight-bold"
              small
              text
              @click="deleteDialog = false"
              >{{ $t("common.no") }}</v-btn
            >
            <v-btn
              color="error"
              class="font-weight-bold"
              small
              text
              :loading="deleteBtnLoading"
              @click="deleteItem()"
              >{{ $t("common.yesDelete") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row> -->
    <!-- footer -->
    <!-- <footer-bar
      v-if="isAuthenticated()"
      :style="
        `margin-left: ${
          !drawerState && !$vuetify.breakpoint.mdAndDown ? '166px' : '0'
        }`
      "
    /> -->
  </v-app>
</template>

<script>
// import { mapGetters } from "vuex";
import goTo from 'vuetify/lib/services/goto'
// import MainNavbar from "./core/components/MainNavbar.vue";
export default {
  data() {
    return {
      snackbar: false,
      snackbarObj: {},
      deleteDialog: false,
      deleteDetails: {},
      showScrollToTop: false
    };
  },
  components: {
    // "app-main-navbar": MainNavbar,
    // "footer-bar": () => import("@/core/components/Footer")
  },
  mounted() {
    //
  },
  // watch: {
  //   deleteBtnLoading: {
  //     handler() {
  //       if (!this.deleteBtnLoading) this.deleteDialog = false;
  //     },
  //     deep: true
  //   }
  // },
  computed: {
    // ...mapGetters(["drawerState", "deleteBtnLoading"]),
    display() {
      var isVisible = false;
      const currentPath = this.$route.path;
      if (currentPath !== "/") {
        // debugger;
        this.$router.options.routes.forEach(element => {
          if (currentPath == element.path) {
            isVisible = true;
          } else if (element.children) {
            element.children.forEach(child => {
              // debugger;
              if (currentPath === "/landlord/" + child.path) {
                isVisible = true;
              }
              if (currentPath.includes("/landlord/details")) {
                isVisible = true;
              }
            });
          }
        });
      }
      return isVisible;
    }
  },
  methods: {
    isAuthenticated() {
      return localStorage.getItem("apollo-done-token") != null;
    },
    onScroll () {
      if (window.pageYOffset <= 0) this.showScrollToTop = false
      else this.showScrollToTop = true
    },
    scrollToTop () {
      goTo(0)
    }
  },
  beforeDestroy() {
    this.$root.$off("snackbar");
    this.$root.$off("deleteDialog");
  }
};
</script>

<style>
.main {
  background-color: #E5E5E5 !important;
}
.custom-bg-color {
  background-color: #E5E5E5 !important;
}
.custom-font {
  font-family: 'Gotham';
}
.v-btn {
  text-transform:none !important;
  font-family: 'Gotham';
  letter-spacing: 0.5px !important;
}
.pointer {
  cursor: pointer;
}
.theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0, 0, 0, 0.048);
  font-family: 'Gotham';
  font-size: 12px !important;
  font-style: normal !important;
  border-color: red !important;
  line-height: 11px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #00abc8 !important;
  }
.custom-button {
  font-family: Gotham;
  color: white !important;
  background: #0075ff !important;
  border-radius: 47px !important;
  letter-spacing: 1px;
}
.grp-btn {
  border-radius: 47px !important;
}
.custom-field {
  max-width: 260px !important;
}

.common-img {
  top: 75px;
}
.card-radius {
  border-radius:  20px !important;
}
.center-align {
  margin-top: 10%;
}
.custom-radius {
  border-radius: 15px !important;
}
.card-custom-horizantal-scroll {
  overflow-x: hidden;
  overflow-y: auto
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
  url(./font/GothamMedium.ttf) format("truetype");
}
</style>
