import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../user/pages/Login.vue'
import i18n from '../locales/i18n.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { display: false, redirectAuthUser: true }
  },
  {
    path: '/user_phone',
    name: 'UserPhone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../user/pages/UserPhoneVerification.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../user/pages/ForgetPassword.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/otp',
    name: 'Otp',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/Otp.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/password_phone_otp',
    name: 'PasswordPhoneOtp',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/PasswordOtpPhone.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/password_email_otp',
    name: 'PasswordEmailOtp',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/PasswordOtpEmail.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */'../landingPage/pages/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/ResetPassword.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/sign_up',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/Signup.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "about" */'../menu/pages/Menu.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/health',
    name: 'Health',
    component: () => import(/* webpackChunkName: "about" */'../health/pages/Health.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/verify_phone/:isForgotPassword?',
    name: 'VerifyPhone',
    component: () => import(/* webpackChunkName: "about" */'../user/pages/VerifyPhone.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/package',
    name: 'Package',
    component: () => import(/* webpackChunkName: "about" */'../package/pages/Package.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "about" */'../payments/pages/Payment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout-payment-thank-you',
    name: 'PaymentSuccess',
    component: () => import(/* webpackChunkName: "about" */'../payments/pages/PaymentSuccess.vue'),
    meta: { requiresAuth: true }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem('apollo-done-token');
  let language = to.params.lang;
  if (!language) {
    language = localStorage.getItem('DIET_PRO_LANG') || 'en';
  }
  i18n.locale = language;

  // redirect unauth users to Signin  page
  if (to.meta.requiresAuth && !auth) {
    next({ name: 'Login', params: { lang: i18n.locale } });
  } else if (to.meta.redirectAuthUser && auth) {
    // redirect Dashboard page for autheticated users
    next({ name: 'Home', params: { lang: i18n.locale } });
    // next({ name: 'Login', params: { lang: i18n.locale } });
  } else {
    next();
  }
});

export default router
