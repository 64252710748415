export default {
  data: function () {
    return {
      //
    }
  },
  computed: {
    //
  },
  methods: {
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    // hasRole(role) {
    //   return new UserRoleSettings().hasRole(role)
    // },
    // isNumber: function (evt) {
    //   evt = evt ? evt : window.event;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },

  }
}