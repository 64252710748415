import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedPackage: {},
    mealChoice: null,
    chosenPlan: null,
    dietitianInput:null,
    otpDetails:null,
  },
  mutations: {
    SELECTED_PACKAGE(state, payload) {
      state.selectedPackage = payload
    },
    MEAL_CHOICE(state, payload) {
      state.mealChoice = payload
    },
    CHOSEN_PLAN(state, payload) {
      state.chosenPlan = payload
    },
    SET_DIETITIAN(state, payload) {
      state.dietitianInput = payload
    },
    SET_OTP_DETAILS(state, payload) {
      state.otpDetails = payload
    }
  },
  getters: {
    selectedPackage: (state) => state.selectedPackage,
    mealChoice: (state) => state.mealChoice,
    chosenPlan: (state) => state.chosenPlan,
    dietitianInput: (state) => state.dietitianInput,
    otpDetails: (state) => state.otpDetails,
  },
  actions: {
  },
  modules: {
  }
})
