<template>
  <base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
      <login-form />
    </template>
  </base-card>
</template>

<script>
export default {
  components: {
    "base-card": () => import("@/core/components/BaseCard"),
    "login-form": () => import("@/user/components/login/LoginForm"),
    "image-card": () => import("@/user/components/userPhone/ImageCard"),
  },
};
</script>
