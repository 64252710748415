import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import format from './plugins/format'
import vuetify from './plugins/vuetify'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import i18n from './locales/i18n.js'
import { createProvider } from './plugins/vue-apollo'
import * as VeeValidate from 'vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from "vue-moment";
import mixin from "../src/mixins/index"
import VueHtmlToPaper from 'vue-html-to-paper';
import './scss/main.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "@/core/methods/validation_rules";


library.add(fas);
const component = [
  { text: 'ValidationProvider', value: ValidationProvider },
  { text: 'ValidationObserver', value: ValidationObserver },
  { text: 'font-awesome-icon', value: FontAwesomeIcon }
]
component.forEach(com => {
  Vue.component(com.text, com.value);
})
Vue.filter('truncate', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.css',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.js',
    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/attributes.json',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/tags.json',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/web-types.json',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.css.map',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.js.map',
    '../styles/pdf.css',
    '../styles/meal_sticker.css'
  ]
}
Vue.use(VueHtmlToPaper, options);
Vue.config.productionTip = false

Vue.mixin(mixin)

process.env.VUE_APP_BASE_URL
extend('required', {
  ...required,
  message: '{_field_}  is required'
});
const plugins = { format, Croppa, moment, VeeValidate }
Object.keys(plugins).forEach(key => {
  Vue.use(plugins[key])
})
new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
